:root {
  --cvx-font-regular: GothamBook, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --cvx-font-regular-italic: GothamBookItalic, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --cvx-font-medium: GothamBookMedium, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --cvx-font-medium-italic: GothamBookMediumItalic, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --cvx-font-bold: GothamBold, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --cvx-font-bold-italic: GothamBoldItalic 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --cvx-font-narrow: GothamNarrowBook, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --cvx-font-narrow-italic: GothamNarrowBookItalic, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --cvx-font-narrow-bold: GothamNarrowBold, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --cvx-font-narrow-italic-bold: GothamNarrowBoldItalic, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
