/* You can add global styles to this file, and also import other style files */
@import 'palette.css';
@import 'fonts.css';
@import 'material-symbols';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body, html {
    height: 100%;
    width: auto;
    margin: auto;
}
body {
    display: flex;
}

sao-root {
    flex: 1;
}



