:root {
    /*
  Primary Colors
  
  Blue and gray triads serve as the intranet's core design colors.
  Limit use of cvx-medium-purple to represent visited links.
  */
    /* Palette 1 */
    --cvx-dark-blue: #0b2d71;
    --cvx-dark-gray: #6b6d6f;
    --cvx-black: #000000;
  
    /* Palette 2 */
    --cvx-medium-blue: #0066b2;
    --cvx-medium-gray: #8c8f93;
    --cvx-white: #fff;
  
    /* Palette 3 */
    --cvx-light-blue: #009dd9;
    --cvx-light-gray: #dbdcdd;
    --cvx-medium-purple: #751569;
  
    /*
  Interactive Colors
  
  Accessibility requires consistent visual cues like color to inform users when something is clickable.
  Use cvx-medium-blue for interactive elements such as buttons and text links.
  Use cvx-dark-blue to indicate hover and press states on buttons.
  */
  
    /*
  Secondary Accent Colors
  
  You may use other colors from the broader Chevron palette in select components to help them stand out on the page.
  Limit use to colors from a single triad per page
   */
  
    /*
  Accessibility: Only use the colors shown here. Other colors from the Chevron palette will not pass accessibility
  requirements. To make sure your site passes accessibility requirements, follow the type size and color rules and review
  the WCAG requirements.
  
  http://www.w3.org/WAI/intro/wcag
  
  */
    /* Secondary Color Palette */
  
    /* Palette 1 */
    /* --cvx-dark-blue: #0B2d71; */
    --cvx-dark-red: #58001c;
    --cvx-dark-teal: #003653;
  
    /* Palette 2 */
    /*--cvx-medium-blue: #0066B2;*/
    --cvx-medium-red: #97002e;
    --cvx-medium-teal: #00708c;
  
    /* Palette 3 */
    --cvx-dark-purple: #3a0d36;
    --cvx-dark-orange: #711b00;
    --cvx-dark-green: #444b0d;
  
    /* Accent Colors */
    --cvx-medium-orange: #e5601f;
    /*--cvx-medium-purple: #751569;*/
  
    --cvx-white-gray: #f3f3f3;
    --cvx-medium-white-gray: #ededee;
    --cvx-medium-light-blue: #337ab7;
    --cvx-blue-grey: #607d8b;
    --cvx-hover-item-gray: #efefef;
    --cvx-hover-item-medium-gray: #dfdfdf;
  }
  